<template>
	<div class="content">

		<div class="top">
			<!-- <img src="../assets/bgtop.png" style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;" alt=""> -->
			<div class="top-nav">
				<!-- <img src="../assets/leyou.png" style="width: 163px;" alt=""> -->
				<img src="../assets/dh-2.png" style="width: 163px;" alt="">
				<div style="display: flex;margin-left: 40px;">
					<div style="color: #fff;cursor: pointer;" @click="back">
						返回首页
					</div>
				</div>
			</div>

		</div>
		<div style="display: flex;justify-content: center;height: 60px;align-items: center;">
			<input v-model="keywords " style="height: 40px;padding-left: 10px;width: 300px;" type="text"
				placeholder="请输入商品名称">
			<div style="margin-left: 30px; cursor: pointer;" @click="getList">立即搜索</div>
		</div>
		<!-- <div style="display: flex;justify-content: center;" v-show="list.length  == 0">
			抱歉,没有商品了~
		<div> -->
		<div style="display: flex;justify-content: center;">
			<div style="margin-top: 20px;width: 1400px;display: flex;flex-wrap: wrap;margin-right: 30px;">
				<div style="width: 650px;margin-bottom: 20px;" v-for="(v,k) in list" :key="k">
					<div style="">

						<div
							style="font-size: 18px;color: #999;width: 100%;padding-bottom: 10px;display: flex;">
							<div style="font-size: 18px;font-weight: bold;color: #000;">
								{{v ? v.name :''}}
							</div>
							<div style="margin-left: 30px;">
								产品型号 {{v ? v.model :''}}
							</div>
						</div>

						<div style="font-size: 16px;color: #999;margin-top: 10px;">颜色: {{v ? v.color :''}} &nbsp; &nbsp;
							&nbsp; 玻璃: {{v ? v.glass :''}}</div>
						<div style="font-size: 18px;margin-top: 10px;margin-bottom: 10px;">{{v ? v.aluminum :''}}&nbsp;
							&nbsp; &nbsp; {{v ? v.craftsmanship :''}}</div>
					</div>
					<img src="../assets/zhutui.png" style="width: 400px;height: 400px;object-fit: cover;" alt="">
				</div>
			</div>
		</div>

			<div style="display: flex;justify-content: center;" v-if="list.length == 0">
				抱歉,没有商品了~
			</div>
			<div style="height: 238px;background-color: #222222;margin-top: 120px;padding-top: 70px;">
				<div style="width: 1400px;margin: 0 auto;display: flex;align-items: center;">
					<img src="../assets/dibu.png" style="width: 200px;height: 71px;" alt="">
					<div style="width: 1px;height: 90px;background-color: #707070;margin-left: 100px;">

					</div>
					<!-- <div style="color: #999999;margin-left: 70px;">
						<div>联系人：孙经理</div>
						<div style="margin-top: 20px;margin-bottom: 20px;">联系电话：158 1566 81982</div>
						<div>门店地址：河南省新乡市封丘县黄陵镇起重工业园</div>
					</div> -->
					<div style="color: #999999;margin-left: 70px;">
						<div style="margin-bottom: 15px;">400-822-0776</div>
						<div>广东 • 佛山</div>
					</div>
				</div>
				<div style="text-align: center;color: #fff;margin-top: 50px;">
					<a href="https://beian.miit.gov.cn/" target="_blank" style="text-align: center;color: #fff;">粤ICP备2024323594号-1</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060502003763" target="_blank" style="text-align: center;color: #fff;">粤公网安备44060502003763号</a>
				</div>
			</div>
	</div>
</template>
<script>
	export default {
		name: '商品列表',
		metaInfo: {
			title:'商品列表',
			meta: [
				{
					name:'keyWords',
					content:'门窗，北美梧桐门窗，北美梧桐，系统门窗，定制门窗，全屋门窗'
				},{
					name:'description',
					content:'北美梧桐门窗是一家集铝合金型材、门窗产品研发、工艺玻璃、钢化玻璃生产与销售为一体的全供应链综合性大型门窗企业'
				}
			]
		},
		components: {},
		data() {
			return {

				list: [],
				img: 'https://app.bmwtong.com/upload/',
				keywords: ''
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			// search(){
			// 		this.getList()
			// }
			getList() {
				this.$axios.post('/api/pc_product/index', {
					keywords: this.keywords
				}).then(res => {
					this.list = res.data.data
				})
			},
			back(){
				// this.$router.back()
				window.history.back();
			}
		},
	}
</script>

<style lang='scss' scoped>
	.top {
		width: 100%;
		height: 100px;
		position: relative;
		background-color: #B5B5B8;

		.top-nav {
			display: flex;
			align-items: center;
			height: 100px;
			width: 1400px;
			margin: 0 auto;
			position: relative;
			z-index: 1;
		}
	}
</style>