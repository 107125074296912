<template>
    <div id="app"><router-view /></div>
</template>

<script>

export default {
  name: 'App',
  metaInfo: {
    title:'首页',
    meta: [
      {
        name:'keyWords',
        content:'门窗，北美梧桐门窗，北美梧桐，系统门窗，定制门窗，全屋门窗'
      },{
        name:'description',
        content:'北美梧桐门窗是一家集铝合金型材、门窗产品研发、工艺玻璃、钢化玻璃生产与销售为一体的全供应链综合性大型门窗企业'
      }
    ]
  },
  components: {
  }
}
</script>

<style>
	
	*{
		margin:0;
		padding:0 ;
	}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
